import styled from 'styled-components'

export const CardBeneficio = styled.div`
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;

  & .icon-trash {
    font-size: 24px;
    color: #f15a24;
    cursor: pointer;
  }
`
