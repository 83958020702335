import React, { useState } from 'react'
import { Row, Col, Collapse, Tooltip, Button } from 'reactstrap'
import { FaInfoCircle } from 'react-icons/fa'
import { MdAttachMoney } from 'react-icons/md'
import { RiComputerLine } from 'react-icons/ri'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { ContainerInfoLead } from '../style' // Importando o ContainerInfoLead estilizado
import { maskMoney } from 'util/masks'

interface Oferta {
  descricao: string
  valor: string
}

interface InformacoesProps {
  matricula: string
  perfil: string
  step: string
  observacaoLead: string
  produtosLead: Oferta[]
}

const InformacoesSite: React.FC<InformacoesProps> = ({
  perfil,
  matricula,
  step,
  observacaoLead,
  produtosLead
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const formatarValor = (valor: string | number) => {
    if (typeof valor === 'number') {
      return `R$ ${maskMoney(valor.toFixed(2))}`
    } else if (typeof valor === 'string') {
      const valorNumerico = parseFloat(valor.replace(/[^\d.-]/g, ''))
      if (isNaN(valorNumerico)) {
        return 'R$ 0,00'
      }
      return `R$ ${maskMoney(valorNumerico.toFixed(2))}`
    } else {
      return 'R$ 0,00'
    }
  }

  return (
    <ContainerInfoLead className="container-fluid">
      <Row className="d-flex title-content">
        <Col>
          <p>
            <RiComputerLine size={25} className="icon" />
            Informações Site
          </p>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <Button color="link" onClick={toggle} className="collapse-btn">
            {isOpen ? (
              <IoIosArrowDown color="#000000" />
            ) : (
              <IoIosArrowUp color="#000000" />
            )}
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={isOpen}>
        <div className="d-flex flex-column justify-content-between">
          <Row className="my-4" style={{ paddingLeft: '6px' }}>
            <Col sm="4">
              <h6 className="fw-bold">Perfil: {perfil}</h6>
            </Col>
            <Col sm="4">
              <h6 className="fw-bold">
                Step Selecionado: {step}
                <FaInfoCircle id="stepInfo" className="info-icon" />
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target="stepInfo"
                  toggle={toggleTooltip}
                >
                  {observacaoLead}
                </Tooltip>
              </h6>
            </Col>
          </Row>
          <Row>
            {produtosLead.map((oferta, index) => (
              <Col
                key={index}
                xs="12"
                sm="6"
                md="4"
                lg="3"
                className="d-flex justify-content-center mb-4"
              >
                <div className="card-produto">
                  <div className="Icon">
                    <MdAttachMoney size={30} />
                  </div>
                  <div className="d-flex flex-column justify-content-between h-100">
                    <span className="title">{oferta.descricao}</span>
                    <span className="price-text">
                      {formatarValor(oferta.valor)}
                    </span>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Collapse>
    </ContainerInfoLead>
  )
}

export default InformacoesSite
