import React from 'react'
import { Card } from './style'

type Props = {
  children?: React.ReactNode
  className?: string
}

function SimpleCard({ children, className }: Props) {
  return <Card className={className}>{children}</Card>
}

export default SimpleCard
