import { Invalid } from 'models/agenda/types'
import { TipoAgendamento } from 'models/atendimento/types'
import { Paginacao } from 'models/types'

export enum TYPES {
  SET_ACTIVE_TAB = '@@agenda/SET_ACTIVE_TAB',
  SET_DIA_DETALHAMENTO = '@@agenda/SET_DIA_DETALHAMENTO',
  CLEAN_DIA_DETALHAMENTO = '@@agenda/CLEAN_DIA_DETALHAMENTO',
  SET_AGENDAMENTO_SELECIONADO = '@@agenda/SET_AGENDAMENTO_SELECIONADO',
  CLEAN_AGENDAMENTO_SELECIONADO = '@@agenda/CLEAN_AGENDAMENTO_SELECIONADO',
  SET_AGENDA_ATENDIMENTOS_EVENTOS = '@@agenda/SET_AGENDA_ATENDIMENTOS_EVENTOS',
  CLEAN_AGENDA_ATENDIMENTOS_EVENTOS = '@@agenda/CLEAN_AGENDA_ATENDIMENTOS_EVENTOS',
  SET_AGENDA_ATENDIMENTOS_DATA = '@@agenda/SET_AGENDA_ATENDIMENTOS_DATA',
  CLEAN_AGENDA_ATENDIMENTOS_DATA = '@@agenda/CLEAN_AGENDA_ATENDIMENTOS_DATA',
  SET_DETALHE_AGENDAMENTO = '@@agenda/SET_DETALHE_AGENDAMENTO',
  CLEAN_DETALHE_AGENDAMENTO = '@@agenda/CLEAN_DETALHE_AGENDAMENTO',
  SET_DIAS_SEMANA = '@@agenda/SET_DIAS_SEMANA',
  CLEAN_DIAS_SEMANA = '@@agenda/CLEAN_DIAS_SEMANA',
  SET_FILTRO_BIGNUMBERS = '@@agenda/SET_FILTRO_BIGNUMBERS',
  CLEAN_FILTRO_BIGNUMBERS = '@@agenda/CLEAN_FILTRO_BIGNUMBERS',
  SET_BIGNUMBERS = '@@agenda/SET_BIGNUMBERS',
  CLEAN_BIGNUMBERS = '@@agenda/CLEAN_BIGNUMBERS',
  SET_VISAO_SUPERVISOR = '@@agenda/SET_VISAO_SUPERVISOR',
  SET_PAGINACAO_VISAO_SUPERVISOR = '@@agenda/SET_PAGINACAO_VISAO_SUPERVISOR',
  CLEAN_VISAO_SUPERVISOR = '@@agenda/CLEAN_VISAO_SUPERVISOR',
  SET_VISAO_COORDENADOR = '@@agenda/SET_VISAO_COORDENADOR',
  SET_PAGINACAO_VISAO_COORDENADOR = '@@agenda/SET_PAGINACAO_VISAO_COORDENADOR',
  CLEAN_VISAO_COORDENADOR = '@@agenda/CLEAN_VISAO_COORDENADOR',
  SET_DATA_REFERENCIA = '@@agenda/SET_DATA_REFERENCIA',
  CLEAN_DATA_REFERENCIA = '@@agenda/CLEAN_DATA_REFERENCIA',
  SET_IS_OPEN_MODAL_REAGENDAMENTO = '@@agenda/SET_IS_OPEN_MODAL_REAGENDAMENTO',
  SET_MODAL_REAGENDAMENTO = '@@agenda/SET_MODAL_REAGENDAMENTO',
  SET_MODAL_REAGENDAMENTO_FIELDS = '@@agenda/SET_MODAL_REAGENDAMENTO_FIELDS',
  CLEAN_MODAL_REAGENDAMENTO = '@@agenda/CLEAN_MODAL_REAGENDAMENTO',
  SET_MODAL_REAGENDAMENTO_INVALIDS_FIELDS = '@@agenda/SET_MODAL_REAGENDAMENTO_INVALIDS_FIELDS',
  CLEAN_MODAL_REAGENDAMENTO_INVALIDS = '@@agenda/CLEAN_MODAL_REAGENDAMENTO_INVALIDS',
  SET_TIPOS_AGENDAMENTOS = '@@agenda/SET_TIPOS_AGENDAMENTOS',

  GET_AGENDA_ATENDIMENTOS_EVENTOS_SAGA = '@@agenda/GET_AGENDA_ATENDIMENTOS_EVENTOS_SAGA',
  GET_AGENDA_ATENDIMENTOS_DATA_SAGA = '@@agenda/GET_AGENDA_ATENDIMENTOS_DATA_SAGA',
  GET_AGENDA_ATENDIMENTO_DETALHE_SAGA = '@@agenda/GET_AGENDA_ATENDIMENTO_DETALHE_SAGA',
  GET_DIAS_SEMANA_SAGA = '@@agenda/GET_DIAS_SEMANA_SAGA',
  GET_BIGNUMBERS_SAGA = '@@agenda/GET_BIGNUMBERS_SAGA',
  GET_VISAO_SUPERVISOR_SAGA = '@@agenda/GET_VISAO_SUPERVISOR_SAGA',
  GET_VISAO_COORDENADOR_SAGA = '@@agenda/GET_VISAO_COORDENADOR_SAGA',
  CONFIRMACAO_AGENDAMENTO_SAGA = '@@agenda/CONFIRMACAO_AGENDAMENTO_SAGA',
  GET_GRAVACAO_SAGA = '@@agenda/GET_GRAVACAO_SAGA',
  GET_TIPOS_AGENDAMENTOS_SAGA = '@@agenda/GET_TIPOS_AGENDAMENTOS_SAGA',
  EDITAR_AGENDAMENTO_SAGA = '@@agenda/EDITAR_AGENDAMENTO_SAGA'
}

export interface AGENDA {
  activeTab: 'calendario' | 'agendamento-diario'
  diaDetalhamento: string
  agendamentoSelecionado: AgendamentoSelecionado
  agendaAtendimentosEventos: Evento[]
  agendaAtendimentosData: {
    horario: string
    agendamentos: Evento[]
  }[]
  detalheAgendamento: DetalheAgendamento
  diasSemana: DiasSemana
  filtroBigNumbers:
    | 'agendados'
    | 'confirmados'
    | 'nao-confirmado'
    | 'expirado'
    | null
  bigNumbers: BigNumbers
  visaoSupervisor: VisaoSupervisor[] | null
  paginacaoVisaoSupervisor: Paginacao
  visaoCoordenador: VisaoCoordenador[] | null
  paginacaoVisaoCoordenador: Paginacao
  dataReferencia: DataReferencia
  tiposAgendamentos: TipoAgendamento[]
  isOpenModalReagendamento: boolean
  modalReagendamento: ModalReagendamento
  modalReagendamentoInvalids: ModalReagendamentoInvalids
}

export interface DetalheAgendamento {
  id: string
  atendimentoId: string
  data: string
  pessoaId: string
  nomePessoa: string
  cpf: string
  horario: string
  tipoAtendimento: string
  tipoAtendimentoId: string
  gravacoes: string | null
  melhorTelefone: string
  confirmado: boolean
  confirmarAgendamento: boolean
  atendimentoConcluidoId: string | null
  observacoes: string | null
  audio: boolean
}

export interface Evento {
  id: string
  data: string
  pessoaId: string
  nomePessoa: string
  horario: string
  tipoAtendimento: string
  atendimentoId: string
  nomeAtendente: string
  gravacao?: null
  confirmado: boolean
  atendimentoConcluidoId: string | null
}

export interface AgendamentoSelecionado {
  pessoaId: string
  atendimentoId: string
}

export interface DiasSemana {
  diasDaSemanas: Dia[]
  proximo: string
  anterior: string
}

export interface Dia {
  diaSemana: string
  dia: string
}

export interface BigNumbers {
  agendamentos: number
  confirmados: number
  naoConfirmados: number
  expirados: number
}

export interface VisaoCoordenador {
  id: string
  nomeLoja: string
  agendamentos: number
  confirmados: number
  naoConfirmados: number
  expirados: number
}

export interface VisaoSupervisor {
  id: string
  nomeConsultor: string
  agendamentos: number
  confirmados: number
  naoConfirmados: number
  expirados: number
}

export interface DataReferencia {
  dataInicio: string
  dataFim: string
}

export interface ModalReagendamento {
  atendimentoAgendadoId: string
  dataAtendimento: string
  horario: string
  tipoAtendimentoId: string
  confirmado: boolean
  confirmarAgendamento: boolean
}

type ModalReagendamentoInvalids = {
  [k in keyof ModalReagendamento]: Invalid
}
