import React from 'react'
import { Background } from './style'

type Props = {
  children?: JSX.Element | JSX.Element[]
}

export function Login({ children }: Props) {
  return <Background>{children}</Background>
}
