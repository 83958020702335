import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  ChangeEvent,
  KeyboardEvent
} from 'react'

import { Tooltip } from '@mui/material'

import { IconButton } from '@mui/material'
import { RiBracesFill } from 'react-icons/ri'
import {
  Wrapper,
  InputWrapper,
  Dropdown,
  IconButtonWrapper,
  VariableContainer,
  VariableItem,
  HighlightedText,
  SearchInput,
  StyledTextarea
} from './style'
interface Variable {
  campoEquivalente: string
  descricao: string
}

interface InputVariableProps {
  rawValue: string
  onRawChange: (newValue: string) => void
  variables: Variable[]
  placeholder?: string
  className?: string
}

const InputVariable = ({
  rawValue: externalRawValue,
  onRawChange,
  variables,
  placeholder,
  className
}: InputVariableProps) => {
  const [internalValue, setInternalValue] = useState<string>('')
  const [internalRawValue, setInternalRawValue] = useState<string>('')
  const [invalidVariables, setInvalidVariables] = useState<string[]>([])
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const memoizedVariables = useMemo(() => variables, [variables])

  useEffect(() => {
    const rawToValue = (raw: string): string => {
      let newValue = raw
      memoizedVariables.forEach((variable) => {
        const regex = new RegExp(
          `\\{\\{${variable.campoEquivalente}\\}\\}`,
          'g'
        )
        newValue = newValue.replace(regex, `{{${variable.descricao}}}`)
      })
      return newValue
    }

    if (
      externalRawValue !== undefined &&
      externalRawValue !== internalRawValue
    ) {
      const newValue = rawToValue(externalRawValue)
      setInternalValue(newValue)
      setInternalRawValue(externalRawValue)
    }
  }, [externalRawValue, memoizedVariables, internalRawValue])

  const valueToRaw = useCallback(
    (value: string): string => {
      let newRawValue = value
      memoizedVariables.forEach((variable) => {
        const regex = new RegExp(`\\{\\{${variable.descricao}\\}\\}`, 'g')
        newRawValue = newRawValue.replace(
          regex,
          `{{${variable.campoEquivalente}}}`
        )
      })
      return newRawValue
    },
    [memoizedVariables]
  )

  const validateRawValue = useCallback(
    (raw: string) => {
      const allVariables = new Set<string>()
      const invalidVariables: string[] = []

      memoizedVariables.forEach((variable) => {
        const regex = new RegExp(
          `\\{\\{${variable.campoEquivalente}\\}\\}`,
          'g'
        )
        raw = raw.replace(regex, `{{${variable.descricao}}}`)
      })

      const regexDisplay = /\{\{(.*?)\}\}/g
      let matchDisplay
      while ((matchDisplay = regexDisplay.exec(raw)) !== null) {
        allVariables.add(matchDisplay[1])
      }

      allVariables.forEach((variableName) => {
        const isValidVariable = memoizedVariables.some(
          (v) => v.descricao === variableName
        )
        if (!isValidVariable) {
          invalidVariables.push(variableName)
        }
      })

      setInvalidVariables(invalidVariables)
    },
    [memoizedVariables]
  )

  useEffect(() => {
    const newRawValue = valueToRaw(internalValue)
    validateRawValue(newRawValue)

    if (onRawChange && newRawValue !== internalRawValue) {
      onRawChange(newRawValue)
      setInternalRawValue(newRawValue)
    }
  }, [
    internalValue,
    onRawChange,
    internalRawValue,
    valueToRaw,
    validateRawValue
  ])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setInternalValue(newValue)

    if (newValue.endsWith('{{')) {
      setIsDropdownOpen(true)
    }
  }

  const addVariable = useCallback(
    (variable: Variable) => {
      const input = inputRef.current

      let newValue = internalValue
      if (newValue.endsWith('{{')) {
        newValue = newValue.slice(0, -2)
      }

      const newDisplayValue = `${newValue}{{${variable.descricao}}}`
      setInternalValue(newDisplayValue)

      input?.setSelectionRange(newDisplayValue.length, newDisplayValue.length)
      setIsDropdownOpen(false)
    },
    [internalValue]
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const input = inputRef.current
      const position = input?.selectionStart || 0
      if (e.key === 'Backspace') {
        const regexDisplay = /\{\{(.*?)\}\}/g
        let matchDisplay
        let newDisplayValue = internalValue

        while ((matchDisplay = regexDisplay.exec(newDisplayValue)) !== null) {
          const startDisplay = matchDisplay.index
          const endDisplay = startDisplay + matchDisplay[0].length

          if (position > startDisplay && position <= endDisplay) {
            e.preventDefault()

            newDisplayValue =
              newDisplayValue.slice(0, startDisplay) +
              newDisplayValue.slice(endDisplay)
            setInternalValue(newDisplayValue)

            return
          }
        }
      }
    },
    [internalValue]
  )

  const filteredVariables = useMemo(
    () =>
      variables.filter((variable) =>
        variable.descricao.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [variables, searchTerm]
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !inputRef.current?.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Wrapper>
      <InputWrapper>
        <StyledTextarea
          type="text"
          value={internalValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className={className}
          innerRef={inputRef}
          isInvalid={invalidVariables.length > 0}
        />
        <Tooltip placement="top" title="Adicionar variável">
          <IconButtonWrapper>
            <IconButton onClick={() => setIsDropdownOpen(true)}>
              {' '}
              <RiBracesFill size={20} color="#5f6973" />
            </IconButton>
          </IconButtonWrapper>
        </Tooltip>
        {isDropdownOpen && (
          <Dropdown ref={dropdownRef}>
            <VariableContainer>
              <span>Chaves reservadas</span>
              <SearchInput
                type="text"
                placeholder="Pesquisar"
                value={searchTerm}
                onChange={(e: any) => setSearchTerm(e.target.value)}
              />
              {filteredVariables.map((variable, idx) => (
                <VariableItem
                  key={idx}
                  isLast={idx === filteredVariables.length - 1}
                  onClick={() => addVariable(variable)}
                >
                  <HighlightedText>
                    {`{{${variable.descricao}}}`}
                  </HighlightedText>
                  <span>{variable.campoEquivalente}</span>
                </VariableItem>
              ))}
            </VariableContainer>
          </Dropdown>
        )}
      </InputWrapper>
    </Wrapper>
  )
}

export default InputVariable
