import React from 'react'
import noData from 'assets/images/no-data.png'
import { NoDataWrapper } from './style'

const NoResultsFound = () => {
  return (
    <NoDataWrapper>
      <img src={noData} />
      <h2>Nenhum resultado encontrado.</h2>
    </NoDataWrapper>
  )
}

export default NoResultsFound
