import React from 'react'
import noData from 'assets/images/no-data.png'
import { NoDataWrapper } from './style'

const NoData = () => {
  return (
    <NoDataWrapper>
      <img src={noData} />
      <h2>Não há nada por aqui...</h2>
    </NoDataWrapper>
  )
}

export default NoData
