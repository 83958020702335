import React, { useEffect, useState } from 'react'
import CustomTable from 'components/CustomTable'
import { ParcelaFgtsItem } from 'models/types'
import { BiMoney } from 'react-icons/bi'
import { Modal, ModalBody } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { COLUMNS_DETALHAMENTO_PARCELAS_FGTS } from 'views/atendimento/constants/columns'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import { toast } from 'react-toastify'

interface DetalhamentoParcelasFgtsProps {
  isOpen: boolean
  onToggle: () => void
  parcelasFgts?: ParcelaFgtsItem[]
  valorTotal: number
  parcela: number
  index: number
}

const DetalhamentoParcelasFgts = ({
  isOpen,
  onToggle,
  parcelasFgts,
  valorTotal,
  parcela,
  index
}: DetalhamentoParcelasFgtsProps) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const [valorParcelaState, setValorParcelaState] = useState<number>(valorTotal)
  const [parcelaState, setParcelaState] = useState<number>(parcela)
  const [selecteds, setSelecteds] = useState<string[]>([])

  const { dispatch } = useRedux()

  useEffect(() => {
    if (isOpen) {
      const parcelasFiltradas =
        parcelasFgts?.slice(0, parcelaState).map((parcela) => parcela.id) ?? []
      setSelecteds(parcelasFiltradas)
      setParcelaState(parcelasFiltradas.length)
      setValorParcelaState(
        parcelasFgts
          ?.slice(0, parcelaState)
          ?.map((parcela) => {
            return Number(parcela.parcelaOriginal)
          })
          .reduce((prev, curr) => prev + curr, 0) ?? 0
      )
    }
  }, [isOpen, parcelaState, parcelasFgts])

  function recalculoPropostaFgts() {
    dispatch(
      atendimentoActions.recalculoPropostaFgtsSaga(
        atendimentosAbertos[index].cpf,
        valorParcelaState,
        parcelaState,
        index
      )
    )
    onToggle()
  }
  const handleSelectionChange = (selectedIds: string[]) => {
    if (selectedIds.length < 2) {
      const firstTwoIds =
        parcelasFgts?.slice(0, 2).map((parcela) => parcela.id) || []
      setSelecteds(firstTwoIds)
      setParcelaState(firstTwoIds.length)
      toast.warn('É necessário manter pelo menos 2 parcelas selecionadas.')
    } else {
      setSelecteds(selectedIds)
      setParcelaState(selectedIds.length)
    }
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={() => onToggle()}>
      <ModalBody>
        <SimpleTitleCard>
          <BiMoney className="icon" />
          Detalhamento parcelas FGTS
        </SimpleTitleCard>
        <CustomTable
          data={parcelasFgts || []}
          columns={COLUMNS_DETALHAMENTO_PARCELAS_FGTS}
          paginationMode="client"
          checkboxSelection={true}
          selectionModel={handleSelectionChange}
          selecteds={selecteds}
        />
        <div className="d-flex justify-content-center my-4">
          <CustomButton
            onClick={() => {
              recalculoPropostaFgts()
            }}
            background="#662D91"
          >
            RECALCULAR
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DetalhamentoParcelasFgts
