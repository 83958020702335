import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { CustomButton } from 'styles/global'
import { removeMaskCPF } from 'util/masks'
import useRedux from 'hooks/useRedux'
import * as indicacaoActions from 'store/modules/indicacao/actions'

interface ElegibilidadeRefinProps {
  isOpen: boolean
  toggle: () => void
  mensagemInegibilidade: string[]
  modalInegibilidade: boolean
  cpf: string
}

const ElegibilidadeRefin = ({
  isOpen,
  toggle,
  mensagemInegibilidade,
  modalInegibilidade,
  cpf
}: ElegibilidadeRefinProps) => {
  const { dispatch } = useRedux()

  const limparMensagem = (mensagem: string) => {
    return mensagem.replace(/- Matrícula: \d+/g, '').trim()
  }

  const todasMensagensIguais = (mensagens: string[] = []) => {
    if (mensagens.length > 0) {
      const mensagemLimpa = limparMensagem(mensagens[0])
      return mensagens.every((msg) => limparMensagem(msg) === mensagemLimpa)
    }
    return false
  }

  const extrairMatriculas = (mensagens: string[] = []) => {
    const matriculas: string[] = []
    mensagens.forEach((mensagem) => {
      const matriculaMatch = mensagem.match(/- Matrícula: (\d+)/)
      if (matriculaMatch) {
        matriculas.push(matriculaMatch[1])
      }
    })
    return matriculas
  }

  const matriculas = extrairMatriculas(mensagemInegibilidade)

  return (
    <Modal isOpen={isOpen || modalInegibilidade} centered>
      <ModalHeader toggle={toggle} className="text-right">
        <AiOutlineExclamationCircle
          className="icon"
          style={{ color: '#F7931E', fontSize: '1.5rem' }}
        />
        Cliente não-elegível
      </ModalHeader>
      <ModalBody className="text-center">
        <div>
          {todasMensagensIguais(mensagemInegibilidade) ? (
            <>
              <p className="text-left">
                {limparMensagem(mensagemInegibilidade[0])}
              </p>

              {matriculas.length > 0 && (
                <p className="text-left">Matrículas: {matriculas.join(', ')}</p>
              )}
            </>
          ) : (
            mensagemInegibilidade.map((mensagem, index) => (
              <p key={index} className="text-left">
                {mensagem}
              </p>
            ))
          )}

          <div className="d-flex justify-content-center">
            <CustomButton
              onClick={() => {
                dispatch(
                  indicacaoActions.criarPropostaIndicacaoSaga(
                    removeMaskCPF(cpf)
                  )
                )
                toggle()
              }}
            >
              CRIAR INDICAÇÃO
            </CustomButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
export default ElegibilidadeRefin
