import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import * as indicacaoActions from 'store/modules/indicacao/actions'
import { removeMaskCPF } from 'util/masks'
import useRedux from 'hooks/useRedux'

interface PropostaIndicacaoProps {
  isOpen: boolean
  toggle: () => void
  cpf: string
  mensagens: string[]
  matriculas: string[]
}

const PropostaIndicacao = ({
  isOpen,
  toggle,
  cpf,
  mensagens,
  matriculas
}: PropostaIndicacaoProps) => {
  const { dispatch } = useRedux()

  const limparMensagem = (mensagem: string) => {
    return mensagem.replace(/- Matrícula: \d+/g, '').trim()
  }

  const todasMensagensIguais = (mensagens: string[] = []) => {
    if (mensagens.length > 0) {
      const mensagemLimpa = limparMensagem(mensagens[0])
      return mensagens.every((msg) => limparMensagem(msg) === mensagemLimpa)
    }
    return false
  }

  const extrairMatriculas = (mensagens: string[] = []) => {
    const matriculas: string[] = []
    mensagens.forEach((mensagem) => {
      const matriculaMatch = mensagem.match(/- Matrícula: (\d+)/)
      if (matriculaMatch) {
        matriculas.push(matriculaMatch[1])
      }
    })
    return matriculas
  }

  const matriculasExtraidas = extrairMatriculas(matriculas)

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <SimpleTitleCard colorIcon="F7931E">
          <AiOutlineExclamationCircle className="icon" />
          Cliente não-elegível
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column gap-1 align-items-center">
          {todasMensagensIguais(mensagens) ? (
            <>
              <p>{mensagens[0]}</p>

              {matriculasExtraidas.length > 0 && (
                <p>Matrículas: {matriculasExtraidas.join(', ')}</p>
              )}
            </>
          ) : (
            <>
              {(mensagens || []).map((mensagem, index) => (
                <p key={index}>{mensagem}</p>
              ))}
              {matriculasExtraidas.length > 0 && (
                <p>Matrículas: {matriculasExtraidas.join(', ')}</p>
              )}
            </>
          )}

          <CustomButton
            onClick={() => {
              dispatch(
                indicacaoActions.criarPropostaIndicacaoSaga(removeMaskCPF(cpf))
              )
              toggle()
            }}
          >
            CRIAR INDICAÇÃO
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default PropostaIndicacao
