import React from 'react'
import { Col, Row } from 'reactstrap'
import { maskDate, maskMoney } from 'util/masks'
import { ContentCardProdutosContratados } from '../style'
import { ProdutosContratados } from 'models/atendimento/types'

interface CardProdutosContratadosProps {
  produto: ProdutosContratados
}

function CardProdutosContratados({ produto }: CardProdutosContratadosProps) {
  switch (produto.produto) {
    case 10:
      return (
        <ContentCardProdutosContratados>
          <Row className="d-flex row-gap-20">
            <Col
              md={4}
              className="align-items-center d-flex column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">{produto.produto}</span>
              </div>
              <div className="d-flex flex-column">
                <label>Banco:</label>
                <span className="strong-black">{produto.banco}</span>
              </div>
            </Col>
            <Col
              md={4}
              className="align-items-center d-flex flex-row column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Quantidade de parcelas:</label>
                <span className="strong-green">
                  {produto.quantidadeParcelas}
                </span>
              </div>
              <div className="d-flex flex-column">
                <label>Valor da parcela:</label>
                <span className="strong-green">
                  R$ {maskMoney(produto.valorParcela.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md={4} className="d-flex align-items-center column-gap-10">
              <div className="d-flex align-items-center justify-content-end mt-2 gap-1">
                <label className="data-contratacao">Data da contratação:</label>
                {maskDate(produto.dataContratacao, 'DD/MM/YYYY')}
              </div>
            </Col>
          </Row>
        </ContentCardProdutosContratados>
      )
    case 60:
      return (
        <ContentCardProdutosContratados>
          <Row className="d-flex row-gap-20">
            <Col
              md={4}
              className="align-items-center d-flex column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">{produto.produto}</span>
              </div>
              <div className="d-flex flex-column">
                <label>Banco:</label>
                <span className="strong-black">{produto.banco}</span>
              </div>
            </Col>
            <Col
              md={4}
              className="align-items-center d-flex flex-row column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Quantidade de parcelas:</label>
                <span className="strong-green">
                  {produto.quantidadeParcelas}
                </span>
              </div>
              <div className="d-flex flex-column">
                <label>Valor da parcela:</label>
                <span className="strong-green">
                  R$ {maskMoney(produto.valorParcela.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md={4} className="d-flex align-items-center column-gap-10">
              <div className="d-flex align-items-center justify-content-end mt-2 gap-1">
                <label className="data-contratacao">Data da contratação:</label>
                {maskDate(produto.dataContratacao, 'DD/MM/YYYY')}
              </div>
            </Col>
          </Row>
        </ContentCardProdutosContratados>
      )
    case 70:
      return (
        <ContentCardProdutosContratados>
          <Row className="d-flex row-gap-20">
            <Col
              md={4}
              className="align-items-center d-flex column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">{produto.produto}</span>
              </div>
              <div className="d-flex flex-column">
                <label>Banco:</label>
                <span className="strong-black">{produto.banco}</span>
              </div>
            </Col>
            <Col
              md={4}
              className="align-items-center d-flex flex-row column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Quantidade de parcelas:</label>
                <span className="strong-green">
                  {produto.quantidadeParcelas}
                </span>
              </div>
              <div className="d-flex flex-column">
                <label>Valor da parcela:</label>
                <span className="strong-green">
                  R$ {maskMoney(produto.valorParcela.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md={4} className="d-flex align-items-center column-gap-10">
              <div className="d-flex align-items-center justify-content-end mt-2 gap-1">
                <label className="data-contratacao">Data da contratação:</label>
                {maskDate(produto.dataContratacao, 'DD/MM/YYYY')}
              </div>
            </Col>
          </Row>
        </ContentCardProdutosContratados>
      )
    case 80:
      return (
        <ContentCardProdutosContratados>
          <Row className="d-flex row-gap-20">
            <Col
              md={4}
              className="align-items-center d-flex column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">{produto.produto}</span>
              </div>
              <div className="d-flex flex-column">
                <label>Banco:</label>
                <span className="strong-black">{produto.banco}</span>
              </div>
            </Col>
            <Col
              md={4}
              className="align-items-center d-flex flex-row column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Valor total:</label>
                <span className="strong-green">{produto.valorTotal}</span>
              </div>
            </Col>
            <Col md={4} className="d-flex align-items-center column-gap-10">
              <div className="d-flex align-items-center justify-content-end mt-2 gap-1">
                <label className="data-contratacao">Data da contratação:</label>
                {maskDate(produto.dataContratacao, 'DD/MM/YYYY')}
              </div>
            </Col>
          </Row>
        </ContentCardProdutosContratados>
      )
    case 90:
      return (
        <ContentCardProdutosContratados>
          <Row className="d-flex row-gap-20">
            <Col
              md={4}
              className="align-items-center d-flex column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">{produto.produto}</span>
              </div>
              <div className="d-flex flex-column">
                <label>Banco:</label>
                <span className="strong-black">{produto.banco}</span>
              </div>
            </Col>
            <Col
              md={4}
              className="align-items-center d-flex flex-row column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Valor total:</label>
                <span className="strong-green">{produto.valorTotal}</span>
              </div>
            </Col>
            <Col md={4} className="d-flex align-items-center column-gap-10">
              <div className="d-flex align-items-center justify-content-end mt-2 gap-1">
                <label className="data-contratacao">Data da contratação:</label>
                {maskDate(produto.dataContratacao, 'DD/MM/YYYY')}
              </div>
            </Col>
          </Row>
        </ContentCardProdutosContratados>
      )
    case 94:
      return (
        <ContentCardProdutosContratados>
          <Row className="d-flex row-gap-20">
            <Col
              md={4}
              className="align-items-center d-flex column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">{produto.produto}</span>
              </div>
              <div className="d-flex flex-column">
                <label>Banco:</label>
                <span className="strong-black">{produto.banco}</span>
              </div>
            </Col>
            <Col md={4}></Col>
            <Col md={4} className="d-flex align-items-center column-gap-10">
              <div className="d-flex align-items-center justify-content-end mt-2 gap-1">
                <label className="data-contratacao">Data da contratação:</label>
                {maskDate(produto.dataContratacao, 'DD/MM/YYYY')}
              </div>
            </Col>
          </Row>
        </ContentCardProdutosContratados>
      )
    case 95:
      return (
        <ContentCardProdutosContratados>
          <Row className="d-flex row-gap-20">
            <Col
              md={4}
              className="align-items-center d-flex column-gap-20 row-gap-10 flex-wrap"
            >
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">{produto.produto}</span>
              </div>
              <div className="d-flex flex-column">
                <label>Banco:</label>
                <span className="strong-black">{produto.banco}</span>
              </div>
            </Col>
            <Col md={4}></Col>
            <Col md={4} className="d-flex align-items-center column-gap-10">
              <div className="d-flex align-items-center justify-content-end mt-2 gap-1">
                <label className="data-contratacao">Data da contratação:</label>
                {maskDate(produto.dataContratacao, 'DD/MM/YYYY')}
              </div>
            </Col>
          </Row>
        </ContentCardProdutosContratados>
      )
    default:
      return (
        <ContentCardProdutosContratados>
          <Row className="d-flex row-gap-20 flex-nowrap">
            <Col md={4} className="d-flex justify-content-between">
              <div
                className="d-flex flex-column me-3"
                style={{ flexBasis: '50%', flexGrow: 1 }}
              >
                <label>Produto:</label>
                <span className="strong-black">{produto.produto}</span>
              </div>
              <div
                className="d-flex flex-column"
                style={{ flexBasis: '50%', flexGrow: 1 }}
              >
                <label>Banco:</label>
                <span className="strong-black">{produto.banco}</span>
              </div>
            </Col>
            <Col md={4} className="d-flex justify-content-between">
              <div
                className="d-flex flex-column me-3"
                style={{ flexBasis: '50%', flexGrow: 1 }}
              >
                <label>Quantidade de parcelas:</label>
                <span className="strong-green">
                  {produto.quantidadeParcelas}
                </span>
              </div>
              <div
                className="d-flex flex-column"
                style={{ flexBasis: '50%', flexGrow: 1 }}
              >
                <label>Valor da parcela:</label>
                <span className="strong-green">
                  R$ {maskMoney(produto.valorParcela.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md={4} className="d-flex flex-column">
              <label className="data-contratacao">Data da contratação:</label>
              <span className="strong-green">
                {maskDate(produto.dataContratacao, 'DD/MM/YYYY')}
              </span>
            </Col>
          </Row>
        </ContentCardProdutosContratados>
      )
  }
}

export default CardProdutosContratados
