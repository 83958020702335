import React from 'react'
import { BlurContent } from './style'
import loadingIcon from 'assets/svg/loading-icon.svg'

interface BlurLoadingProps {
  isLoading: boolean
}

const BlurLoading = ({ isLoading }: BlurLoadingProps) => {
  return (
    <>
      {isLoading && (
        <BlurContent>
          <img src={loadingIcon} alt="Loading sistema" width="100" />
        </BlurContent>
      )}
    </>
  )
}

export default BlurLoading
