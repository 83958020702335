import React from 'react'
import { TopLoadingContent } from './style'

const TopLoading = () => {
  return (
    <TopLoadingContent>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </TopLoadingContent>
  )
}

export default TopLoading
