import React, { useEffect, useState } from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import SimpleCard from 'components/SimpleCard'
import SimpleTab from 'components/SimpleTab/SimpleTab'
import { FiDownload, FiGlobe } from 'react-icons/fi'
import { Col, Row, TabPane } from 'reactstrap'
import { InfosInputs, SimpleTitleCard } from 'styles/global'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { ATENDIMENTO } from 'store/modules/atendimento/types'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import useRedux from 'hooks/useRedux'
import * as yup from 'yup'
import { maskCEP, maskCPF, maskNumber, removeMaskMoney } from 'util/masks'
import { toast } from 'react-toastify'
import { InfoClienteReq } from 'models/atendimento/types'
import BeneficiosTabs from '../BeneficiosTabs'
import { validaCpf } from 'util/validations'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { EscolhaPerfil } from '../Modals/EscolhaPerfil'

/**
 * Informações
 * TabId = 3
 * **/

interface Props {
  index: number
}

const Informacoes = ({ index }: Props) => {
  const { atendimentosAbertos } = useSelector<ApplicationState, ATENDIMENTO>(
    (state) => state.atendimento
  )

  const [beneficios, setBeneficios] = useState<
    {
      id: number
      descricao: string
      component: JSX.Element
    }[]
  >([])

  const [openModalBeneficio, setOpenModalBeneficio] = useState(false)

  const { dispatch } = useRedux()

  function onChange(value: string | number, field: string) {
    switch (field) {
      case 'cep':
        dispatch(atendimentoActions.setInformacoesField(value, field, index))
        if (value.toString().length === 9) {
          dispatch(atendimentoActions.getDadosEnderecoSaga(index))
        }
        break
      case 'ufNascimento':
        dispatch(
          atendimentoActions.setInformacoesField('', 'cidadeNascimento', index)
        )
        dispatch(atendimentoActions.setInformacoesField(value, field, index))
        break
      case 'siglaEstado':
        dispatch(atendimentoActions.setInformacoesField('', 'cidade', index))
        dispatch(atendimentoActions.setInformacoesField(value, field, index))
        break
      default:
        dispatch(atendimentoActions.setInformacoesField(value, field, index))
        break
    }
  }

  useEffect(() => {
    dispatch(
      atendimentoActions.getInformacoesClienteSaga(
        atendimentosAbertos[index].cpf,
        index
      )
    )
    dispatch(atendimentoActions.getPerfilClienteSaga(index))
    dispatch(
      atendimentoActions.getTelefonesSaga(atendimentosAbertos[index].cpf, index)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const matriculas: typeof beneficios = []
    atendimentosAbertos[index].tabInformacoes.infoCliente.matriculas?.map(
      (matricula, i) => {
        matriculas.push({
          id: i,
          descricao: matricula.descricaoPerfil || 'Descrição indisponível',
          component: (
            <BeneficiosTabs
              matricula={
                atendimentosAbertos[index].tabInformacoes.infoCliente
                  .matriculas?.[i]
              }
              indexPerfil={i}
              index={index}
            />
          )
        })
      }
    )
    setBeneficios(matriculas)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabInformacoes.infoCliente.matriculas])

  useEffect(() => {
    dispatch(
      atendimentoActions.getCidadesSaga(
        index,
        atendimentosAbertos[index].tabInformacoes.infoCliente.siglaEstado
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabInformacoes.infoCliente.siglaEstado])

  useEffect(() => {
    dispatch(
      atendimentoActions.getCidadesNascimentoSaga(
        index,
        atendimentosAbertos[index].tabInformacoes.infoCliente.ufNascimento
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentosAbertos[index].tabInformacoes.infoCliente.ufNascimento])

  const schema: yup.SchemaOf<InfoClienteReq> = yup.object().shape({
    nome: yup.string().required().min(3).max(100),
    cpf: yup
      .string()
      .test('test-invalid-cpf', 'cpf inválido', (cpf) => validaCpf(cpf || ''))
      .required(),
    dataNascimento: yup.string().required(),
    nomePai: yup.string().nullable(),
    nomeMae: yup.string().required().min(3).max(100),
    numeroIdentidade: yup.string().required().max(15),
    orgaoEmissorId: yup.string().required(),
    ufOrgaoEmissor: yup.string().required(),
    dataEmissao: yup.string().required(),
    ddd: yup.string().required().min(2).max(3),
    numero: yup.string().required().min(8).max(9),
    email: yup.string().email().nullable(),
    sexo: yup.string().required(),
    estadoCivil: yup.string().required(),
    nomeConjuge: yup
      .string()
      .when('estadoCivil', {
        is: (estadoCivil: string) =>
          estadoCivil === 'CASADO' || estadoCivil === 'MARITAL',
        then: yup.string().required().min(3).max(100)
      })
      .nullable(),
    cidadeNascimento: yup.string().required(),
    ufNascimento: yup.string().required(),
    matriculas: yup.array().of(
      yup.object().shape({
        id: yup.string().nullable(),
        numeroMatricula: yup
          .string()
          .max(20)
          .when('tipoPerfil', {
            is: (tipoPerfil: number) =>
              tipoPerfil !== 2 && tipoPerfil !== 0 && tipoPerfil !== 6,
            then: yup.string().required()
          })
          .nullable(),
        tipoPerfil: yup.number().nullable(),
        descricaoPerfil: yup.string().nullable(),
        tipoPerfilId: yup.string().required(),
        convenioId: yup
          .string()
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup.string().required()
          })
          .nullable(),
        margemDisponivel: yup
          .number()
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup.number().moreThan(0).lessThan(1000000000.0)
          })
          .nullable()
          .transform((_, val) => (val === Number(val) ? val : null)),
        margemCartaoDisponivel: yup
          .number()
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup.number().moreThan(0).lessThan(1000000000.0)
          })
          .nullable()
          .transform((_, val) => (val === Number(val) ? val : null)),
        margemCartaoBeneficio: yup
          .number()
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup.number().moreThan(0).lessThan(1000000000.0)
          })
          .nullable()
          .transform((_, val) => (val === Number(val) ? val : null)),
        contaCorrenteId: yup.string().nullable(),
        tipoContaId: yup.string().required(),
        bancoDadosBancariosId: yup.string().required(),
        numeroAgencia: yup.number().required().max(99999999999),
        digitoVerificadorAgencia: yup
          .number()
          .nullable()
          .max(9)
          .transform((_, val) => (val === Number(val) ? val : null)),
        numeroConta: yup.number().required().max(99999999999),
        digitoVerificadorConta: yup.number().required().max(9)
      })
    ),
    escolaridadeId: yup.string().required(),
    cep: yup.string().required().min(9).max(9),
    cidade: yup.string().required(),
    siglaEstado: yup.string().required(),
    logradouro: yup.string().required().min(3).max(100),
    numeroEndereco: yup.string().required().max(6),
    bairro: yup.string().required().min(3).max(100),
    complemento: yup.string().nullable(),
    profissaoId: yup.string().required()
  })

  function onSave() {
    dispatch(atendimentoActions.cleanInvalidsInformacoesField(index))
    schema
      .validate(
        {
          nome: atendimentosAbertos[index].tabInformacoes?.infoCliente.nome,
          cpf: atendimentosAbertos[index].tabInformacoes?.infoCliente.cpf,
          dataNascimento:
            atendimentosAbertos[index].tabInformacoes?.infoCliente
              .dataNascimento,
          nomePai:
            atendimentosAbertos[index].tabInformacoes?.infoCliente.nomePai,
          nomeMae:
            atendimentosAbertos[index].tabInformacoes?.infoCliente.nomeMae,
          numeroIdentidade:
            atendimentosAbertos[index].tabInformacoes?.infoCliente
              .numeroIdentidade,
          orgaoEmissorId:
            atendimentosAbertos[index].tabInformacoes?.infoCliente
              .orgaoEmissorId,
          ufOrgaoEmissor:
            atendimentosAbertos[index].tabInformacoes?.infoCliente
              .ufOrgaoEmissor,
          dataEmissao:
            atendimentosAbertos[index].tabInformacoes?.infoCliente.dataEmissao,
          ddd: atendimentosAbertos[index].tabInformacoes?.infoCliente.ddd,
          numero: maskNumber(
            atendimentosAbertos[index].tabInformacoes?.infoCliente.numero
          ),
          email: atendimentosAbertos[index].tabInformacoes?.infoCliente.email,
          sexo: atendimentosAbertos[index].tabInformacoes?.infoCliente.sexo,
          estadoCivil:
            atendimentosAbertos[index].tabInformacoes?.infoCliente.estadoCivil,
          nomeConjuge:
            atendimentosAbertos[index].tabInformacoes?.infoCliente.nomeConjuge,
          cidadeNascimento:
            atendimentosAbertos[index].tabInformacoes?.infoCliente
              .cidadeNascimento,
          ufNascimento:
            atendimentosAbertos[index].tabInformacoes?.infoCliente.ufNascimento,
          matriculas: atendimentosAbertos[
            index
          ].tabInformacoes?.infoCliente.matriculas?.map((matricula) => {
            return {
              id: matricula.id,
              numeroMatricula: matricula.numeroMatricula,
              tipoPerfil: matricula.tipoPerfil,
              descricaoPerfil: matricula.descricaoPerfil,
              tipoPerfilId: matricula.tipoPerfilId,
              convenioId: matricula.convenioId,
              margemDisponivel: Number(
                removeMaskMoney(matricula.margemDisponivel)
              ),
              margemCartaoDisponivel: Number(
                removeMaskMoney(matricula.margemCartaoDisponivel)
              ),
              margemCartaoBeneficio: Number(
                removeMaskMoney(matricula.margemCartaoBeneficio)
              ),
              contaCorrenteId: matricula.contaCorrenteId,
              tipoContaId: matricula.tipoContaId,
              bancoDadosBancariosId: matricula.bancoDadosBancariosId,
              numeroAgencia: matricula.numeroAgencia,
              digitoVerificadorAgencia: matricula.digitoVerificadorAgencia,
              numeroConta: matricula.numeroConta,
              digitoVerificadorConta: matricula.digitoVerificadorConta
            }
          }),
          escolaridadeId:
            atendimentosAbertos[index].tabInformacoes?.infoCliente
              .escolaridadeId,
          cep: atendimentosAbertos[index].tabInformacoes?.infoCliente.cep,
          cidade: atendimentosAbertos[index].tabInformacoes?.infoCliente.cidade,
          siglaEstado:
            atendimentosAbertos[index].tabInformacoes?.infoCliente.siglaEstado,
          logradouro:
            atendimentosAbertos[index].tabInformacoes?.infoCliente.logradouro,
          numeroEndereco:
            atendimentosAbertos[index].tabInformacoes?.infoCliente
              .numeroEndereco,
          bairro: atendimentosAbertos[index].tabInformacoes?.infoCliente.bairro,
          complemento:
            atendimentosAbertos[index].tabInformacoes?.infoCliente.complemento,
          profissaoId:
            atendimentosAbertos[index].tabInformacoes?.infoCliente.profissaoId
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(atendimentoActions.salvarInformacoesClienteSaga(index))
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          if (e.path?.includes('[')) {
            const e1 = e.path?.split('[')
            const e2 = e.path?.match(/\[(.*?)\]/) || []
            const e3 = e.path?.split('.')
            if (e.path) {
              dispatch(
                atendimentoActions.setInvalidsInformacoesFieldArray(
                  true,
                  e1[0],
                  Number(e2[1]),
                  e3[1],
                  index
                )
              )
            }
          } else {
            dispatch(
              atendimentoActions.setInvalidsInformacoesField(
                true,
                e.path || '',
                index
              )
            )
          }
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  return (
    <TabPane tabId={3}>
      <SimpleCard>
        <div className="container-fluid">
          <Row>
            <Col className="d-flex justify-content-end">
              <div onClick={() => onSave()} className="btn-outline-purple">
                <FiDownload /> Salvar Informações
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <SimpleTitleCard>
                <FiGlobe className="icon" /> Benefícios
              </SimpleTitleCard>
            </Col>
          </Row>
          <SimpleTab
            tabs={beneficios}
            activeTab={
              atendimentosAbertos[index].tabInformacoes.activeTabBeneficio
            }
            actionSetTab={(e) => {
              dispatch(atendimentoActions.setActiveTabBeneficio(e, index))
            }}
          />
          <Row>
            <Col className="d-flex">
              <div
                onClick={() => setOpenModalBeneficio(true)}
                className="btn-outline-purple my-2"
              >
                <AiOutlinePlusCircle />
                Adicionar benefício
              </div>
            </Col>
          </Row>
          <EscolhaPerfil
            isOpen={openModalBeneficio}
            onToggle={() => {
              setOpenModalBeneficio(!openModalBeneficio)
            }}
            onAdicionar={(e) => {
              dispatch(atendimentoActions.adicionarPerfil(e, index))
            }}
          />
        </div>
        <SimpleTitleCard>
          <FiGlobe className="icon" /> Informações básicas
        </SimpleTitleCard>
        <div className="container-fluid mb-4">
          <Row>
            <Col md={6}>
              <label className="label-12">Nome completo*</label>
              <InputCustom
                name="nome"
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'nome')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente.nome
                }
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids.nome
                }
                maxLength={100}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label className="label-12">Gênero*</label>
              <CustomSelect
                name="sexo"
                options={
                  atendimentosAbertos[index].tabInformacoes?.optionsSelect
                    .genero
                }
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'sexo')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente.sexo
                }
                accessorLabel="descricao"
                accessorValue="value"
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids.sexo
                }
              />
            </Col>
            <Col md={6}>
              <label className="label-12">Profissão*</label>
              <CustomSelect
                name="profissaoId"
                options={
                  atendimentosAbertos[index].tabInformacoes?.optionsSelect
                    .profissoes
                }
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'profissaoId')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .profissaoId
                }
                accessorLabel="titulo"
                accessorValue="id"
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .profissaoId
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className="label-12">RG*</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'numeroIdentidade')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .numeroIdentidade
                }
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .numeroIdentidade
                }
                maxLength={15}
              />
            </Col>
            <Col md={2}>
              <label className="label-12">Orgão emissor*</label>
              <CustomSelect
                options={
                  atendimentosAbertos[index].tabInformacoes?.optionsSelect
                    .orgaoEmissor
                }
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'orgaoEmissorId')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .orgaoEmissorId
                }
                accessorLabel="nome"
                accessorValue="id"
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .orgaoEmissorId
                }
              />
            </Col>
            <Col md={2}>
              <label className="label-12">UF emissor*</label>
              <CustomSelect
                options={
                  atendimentosAbertos[index].tabInformacoes?.optionsSelect
                    .ufOrgaoEmissor
                }
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'ufOrgaoEmissor')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .ufOrgaoEmissor
                }
                accessorLabel="nome"
                accessorValue="sigla"
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .ufOrgaoEmissor
                }
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Data de expedição*</label>
              <InputCustom
                type="date"
                placeholder="Digite aqui"
                onChange={(e) => {
                  const value = e.target.value
                  if (value.length === 10) {
                    const year = value.split('-')[0]

                    if (year.length > 4) {
                      toast.warn('O ano deve ter no máximo 4 dígitos.')
                      return
                    }

                    onChange(value, 'dataEmissao')
                  }
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .dataEmissao
                }
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .dataEmissao
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className="label-12">CPF*</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(maskCPF(e.target.value), 'cpf')
                }}
                value={maskCPF(
                  atendimentosAbertos[index].tabInformacoes?.infoCliente.cpf
                )}
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids.cpf
                }
                maxLength={14}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Data de nascimento*</label>
              <InputCustom
                type="date"
                placeholder="Digite aqui"
                onChange={(e) => {
                  const value = e.target.value
                  if (value.length === 10) {
                    const year = value.split('-')[0]
                    if (year.length > 4) {
                      toast.warn('O ano deve ter no máximo 4 dígitos.')
                      return
                    }

                    onChange(value, 'dataNascimento')
                  }
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .dataNascimento
                }
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .dataNascimento
                }
              />
            </Col>
          </Row>
        </div>
        <div className="linha-horizontal mb-2"></div>
        <SimpleTitleCard>
          <FiGlobe className="icon" /> Informações complementares
        </SimpleTitleCard>
        <div className="container-fluid mb-4">
          <Row>
            <Col md={4}>
              <label className="label-12">DDD*</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(maskNumber(e.target.value), 'ddd')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente.ddd
                }
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids.ddd
                }
                maxLength={3}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Número*</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(maskNumber(e.target.value), 'numero')
                }}
                value={maskNumber(
                  atendimentosAbertos[index].tabInformacoes?.infoCliente.numero
                )}
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids.numero
                }
                maxLength={9}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Escolaridade*</label>
              <CustomSelect
                options={
                  atendimentosAbertos[index].tabInformacoes?.optionsSelect
                    .escolaridade
                }
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'escolaridadeId')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .escolaridadeId
                }
                accessorLabel="descricao"
                accessorValue="id"
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .escolaridadeId
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className="label-12">UF nascimento*</label>
              <CustomSelect
                options={
                  atendimentosAbertos[index].tabInformacoes?.optionsSelect
                    .ufNascimento
                }
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'ufNascimento')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .ufNascimento
                }
                accessorLabel="nome"
                accessorValue="sigla"
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .ufNascimento
                }
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Cidade de nascimento*</label>
              <CustomSelect
                options={
                  atendimentosAbertos[index].tabInformacoes?.optionsSelect
                    .cidadeNascimento
                }
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'cidadeNascimento')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .cidadeNascimento
                }
                accessorLabel="nome"
                accessorValue="nome"
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .cidadeNascimento
                }
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Estado civil*</label>
              <CustomSelect
                options={
                  atendimentosAbertos[index].tabInformacoes?.optionsSelect
                    .estadoCivil
                }
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'estadoCivil')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .estadoCivil
                }
                accessorLabel="descricao"
                accessorValue="value"
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .estadoCivil
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className="label-12">
                Nome do Cônjuge
                {(atendimentosAbertos[index].tabInformacoes?.infoCliente
                  .estadoCivil === 'CASADO' ||
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .estadoCivil === 'MARITAL') &&
                  '*'}
              </label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'nomeConjuge')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .nomeConjuge || ''
                }
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .nomeConjuge
                }
                maxLength={100}
              />
              <InfosInputs>
                Obrigatório se o estado civil for casado ou marital
              </InfosInputs>
            </Col>
            <Col md={4}>
              <label className="label-12">Nome da mãe*</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'nomeMae')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente.nomeMae
                }
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids.nomeMae
                }
                maxLength={100}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Nome do pai</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'nomePai')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .nomePai || ''
                }
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids.nomePai
                }
                maxLength={100}
              />
            </Col>
          </Row>
        </div>
        <div className="container-fluid  mb-4">
          <Row>
            <Col md={4}>
              <label className="label-12">CEP*</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(maskCEP(e.target.value), 'cep')
                }}
                value={maskCEP(
                  atendimentosAbertos[index].tabInformacoes?.infoCliente.cep
                )}
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids.cep
                }
                maxLength={9}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">UF*</label>
              <CustomSelect
                options={
                  atendimentosAbertos[index].tabInformacoes?.optionsSelect.uf
                }
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'siglaEstado')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .siglaEstado
                }
                accessorLabel="nome"
                accessorValue="sigla"
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .siglaEstado
                }
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Cidade*</label>
              <CustomSelect
                options={
                  atendimentosAbertos[index].tabInformacoes?.optionsSelect
                    .cidade
                }
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'cidade')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente.cidade
                }
                accessorLabel="nome"
                accessorValue="nome"
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids.cidade
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className="label-12">Bairro*</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'bairro')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente.bairro
                }
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids.bairro
                }
                maxLength={100}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Logradouro*</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'logradouro')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .logradouro
                }
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids.logradouro
                }
                maxLength={100}
              />
            </Col>
            <Col md={4}>
              <label className="label-12">Número*</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(maskNumber(e.target.value), 'numeroEndereco')
                }}
                value={maskNumber(
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .numeroEndereco
                )}
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .numeroEndereco
                }
                maxLength={6}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label className="label-12">Complemento</label>
              <InputCustom
                type="text"
                placeholder="Digite aqui"
                onChange={(e) => {
                  onChange(e.target.value, 'complemento')
                }}
                value={
                  atendimentosAbertos[index].tabInformacoes?.infoCliente
                    .complemento || ''
                }
                invalid={
                  atendimentosAbertos[index].tabInformacoes?.invalids
                    .complemento
                }
                maxLength={50}
              />
            </Col>
          </Row>
        </div>
      </SimpleCard>
    </TabPane>
  )
}

export default Informacoes
