import { Reducer } from 'redux'
import { LOADING, TYPES } from './types'

const INITIAL_STATE: LOADING = {
  isLoading: false
}

const reducer: Reducer<LOADING> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_LOADING:
      return { ...state, isLoading: action.value }
    default:
      return state
  }
}

export default reducer
